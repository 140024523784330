import 'leaflet/dist/leaflet.css';
import {MapContainer, TileLayer} from 'react-leaflet';
import {Carrier} from "./Carrier";


const Map = () => {
    return <MapContainer center={[47.9517, 2.5029]} zoom={7} style={{ height: '100vh', width: '100%' }}>
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"/>
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png"/>
        <Carrier carrier={{"raw": "intercites", "pretty": "Intercites"}}/>
        <Carrier carrier={{"raw": "ter", "pretty": "TER"}}/>
        <Carrier carrier={{"raw": "tgv", "pretty": "TGV"}}/>
        <Carrier carrier={{"raw": "transilien", "pretty": "Transilien"}}/>
        <Carrier carrier={{"raw": "eurostar", "pretty": "Eurostar"}}/>
    </MapContainer>
};

export default Map;

/*
    <Carrier carrier={{"raw": "ter", "pretty": "TER"}}/>
    <Carrier carrier={{"raw": "tgv", "pretty": "TGV"}}/>
    <Carrier carrier={{"raw": "transilien", "pretty": "Transilien"}}/>
    <Carrier carrier={{"raw": "eurostar", "pretty": "Eurostar"}}/>
*/