import React from 'react';
import {useEffect, useState} from "react";
import L from "leaflet";
import {Marker, Polyline, Popup} from "react-leaflet";

function date_to_hour(a_time, d_time) {
    const a_heures = ('0' + a_time.getHours()).slice(-2);
    const a_minutes = ('0' + a_time.getMinutes()).slice(-2);
    const d_heures = ('0' + d_time.getHours()).slice(-2);
    const d_minutes = ('0' + d_time.getMinutes()).slice(-2);

    if (a_heures === d_heures && a_minutes === d_minutes) {
        return `${d_heures}:${d_minutes}`;
    } else {
        return `${a_heures}:${a_minutes} -> ${d_heures}:${d_minutes}`;
    }
}

export const Carrier = (props) => {
    const [markers, setMarkers] = useState({});
    const [lines, setLines] = useState([]);
    const [stations, setStations] = useState([]);

    useEffect(() => {
        const fetchData = () => {
            fetch(`https://railradar.fr/json/${props.carrier.raw}.json?datetime=${Date.now()}`)
//            fetch(`http://127.0.0.1:8000/${props.carrier.raw}.json?datetime=${Date.now()}`)
                .then(response => response.json())
                .then(data => {
                    setMarkers(data["features"]);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchData();
        const intervalId = setInterval(fetchData, 1000);

        return () => clearInterval(intervalId);
    }, [props.carrier]);

    const TrainIcon = new L.Icon({
        iconUrl: `https://railradar.fr/images/${props.carrier.raw}.png`,
        iconSize: [15, 15],
        iconAnchor: [7.5, 7.5],
        popupAnchor: [0, 0],
    });

    const StationIcon = new L.Icon({
        iconUrl: `https://railradar.fr/images/${props.carrier.raw}.png`,
        iconSize: [15, 15],
        iconAnchor: [7.5, 7.5],
        popupAnchor: [0, 0],
    });

    return <div>
        {Object.entries(markers).map(([id, point]) => {
            return <Marker
                key={id}
                position={[parseFloat(point.geometry.coordinates[1]), parseFloat(point.geometry.coordinates[0])]}
                icon={TrainIcon}
                eventHandlers={{
                    click: (hmm) => {
                        let previous_stop_id = null;
                        Object.entries(point.properties.stops).map(([stop_id, stop]) => {
                            setStations((prevStations) => [...prevStations, [stop.lat, stop.lon]])
                            if (stop_id === "0") {
                                previous_stop_id = stop.id;
                                return undefined;
                            }
                            fetch(`https://railradar.fr/stops/${previous_stop_id}.to.${stop.id}.json`)
                                .then(response => response.json())
                                .then(data => {
                                    setLines((prevLines) => [...prevLines, data])
                                })
                                .catch(error => {console.warn("what's the error ?", error)})
                            previous_stop_id = stop.id;
                        })
                    },
                    popupclose: (hmm) => {
                        setLines([])
                        setStations([])
                    }
                }}
            >
                <Popup autoPan={false}>
                    {props.carrier.pretty}<br/><br/>
                    {Object.entries(point.properties.stops).map(([stop_id, stop_detail]) => {
                        let a_time = new Date(stop_detail.a_time * 1000)
                        let d_time = new Date(stop_detail.d_time * 1000)

                        return <React.Fragment key={stop_id}>
                            {stop_detail.name} {date_to_hour(a_time, d_time)}<br/>
                        </React.Fragment>
                    })}<br/>
                    Vitesse maximale : {point.properties.max_speed}<br/>
                    Vitesse actuelle : {point.properties.estimated_speed}<br/>
                    x {point.geometry.coordinates[0]} y {point.geometry.coordinates[1]}<br/>
                </Popup>
            </Marker>
        })}
        {Object.entries(lines).map(([line_id, line]) => {
            const reversed_list = line.geometry.coordinates.map(coords => [coords[1], coords[0]]);

            return <Polyline pathOptions={{color: "purple"}} key={line_id} positions={reversed_list}/>
        })}
        {Object.entries(stations).map(([station_id, station]) => {
            return <div/> //<Marker key={station_id} position={station} icon={StationIcon}/>
        })}
    </div>
}
